/* eslint-disable no-trailing-spaces */

import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { About, Footer, Header, Experience, Work } from './container';
// import { Navbar } from './components';
import './App.scss';

function HelloWorld() {
  return (
    <div className="hello-container">
      <h1 className="hello-text">Hello World!</h1>
    </div>
  );
}

const App = () => (
  <div className="app">
    <HelloWorld />
  </div>
);

export default App;

// const App = () => {
//   return (
//     <div className="app">
//  <Router>
//       <Routes>
//         <Route path="/" element={<HelloWorld />} />
//       </Routes> 
//       <Navbar />
//       <Header />
//       <About />
//       <Work />
//       <Experience /> */}
//       <Testimonial />
//       <Footer />
//     </Router>
//     </div>
//   )
// }
